.game-menu {
  display: flex;
  justify-content: space-between;
  color: var(--text-color-one);
  font-size: 18px;
  align-items: center;
}

.game-menu .menu-items {
  padding: 30px 0;
  display: flex;
}

.game-menu .menu-items ul {
  margin-block-start: 0;
  margin-block-end: 0;
  display: flex;
  padding: 0;
}

.game-menu .menu-items ul li {
  list-style: none;
  margin: 0 15px;
}

.game-menu .menu-items ul li a {
  font-size: 18px;
  color: var(--text-color-one);
}

.game-menu .ant-select {
  margin-left: 10px;
}

.game-menu .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color) !important;
  box-shadow: none;
  color: var(--text-color-one);
}
.ant-select-dropdown {
  background-color: var(--secondary-color) !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-select-item-option-selected:not(.ant-select-item-option-disabled):hover {
  background-color: var(--active-links) !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(--btn-arrow) !important;
}

.game-menu .display-type .ant-tabs {
  color: var(--text-color-one);
}

.game-menu .display-type {
  display: flex;
}

.games-page-section-wrapper .ant-divider {
  margin: 15px 0;
  border-top: 0.1px solid var(--text-color-one) !important;
}



.listGridIcon img{width: 25px; display: inline-block; }
.listGridIcon .active{filter: invert(96%) sepia(34%) saturate(6024%) hue-rotate(300deg) brightness(109%) contrast(130%);}