.highlyratedbg{
    background: url('../../../assets/highlyrated.jpg');
    
}
.highlyratedText{
    margin-top: 0px;
    font-size:28px;
    color:#FFF;
    font-weight: 400;
}
.highlyratedCol{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
}
.highlyratedBox{
    background: linear-gradient(180deg, #737373 0%, #262626 100%);
    border-radius: 10px;
    color: #FFF;
    padding:30px;
    margin-top: 180px;

}
.highlyratedBox img{
    border:2px solid var(--color-one);
}

.highlyratedBox img{width: 100%;border-radius: 10px;     transition: ease-in-out .2s;}
.highlyratedBox h4{
    color: #FFF;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 10px;
}
.highlyratedBox .subheading{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin-top: 0px;
}

.highlyratedBox p{
    color:var(--color-three);
    line-height: 30px;

}


@media only screen and (max-width : 768px) {
    .highlyrated{
        display: none;
    }
    
}



.position-relative{
    margin-top: -168px;
    position: relative;
}


.highlyratedTextt{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: 29px;
    overflow: hidden;
}