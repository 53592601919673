.trendingSectionbg{
 
    background: url('../../../assets/trendingbg.jpg');
    
} 
.trendingHeaderSection{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.trendingGrid{
    display: grid;
    grid-gap:15px;
    grid-template-columns: 1fr 1fr 1fr 1fr;}
@media only screen and (max-width : 768px) { .trendingSection{ display: none; }}


.trendingSection{
    background-position: bottom !important;
    background-size: cover !important;
}