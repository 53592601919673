
@media only screen and (min-width :600px) {
    .mobileSlider{display:none    }
}
.mobileSlider .rec-dot_active{
    background: var(--color-one);
}
.mobileSlider .rec-carousel-wrapper{
    margin: 0px !important;
    z-index: 9999;
}
.mobileSlider .rec-slider-container{
    margin:0px !important
}
.mobileSlider .rec-pagination{
    margin-top: -52px;
    z-index: 999;
}
.mobileSlider img{
    width: 100vw !important;
}
.mobileSlider .rec-dot {
    box-shadow: 0 0 1px 2px rgb(255 255 255 / 100%);
}
.mobileSlider .rec-arrow-right, .mobileSlider .rec-arrow-left{
    margin-top: 0px !important;
    background: transparent !important;
    background-color: transparent !important;
    box-shadow: none !important;
}
.mobileSlider .rec-arrow-left{
    left: 0px;
    z-index: 9999;
}
.mobileSlider .rec-arrow-right{
    right: 0px;
}
.homepageComponent{
    display: grid;
    align-items: center;
    grid-template-columns: 1.3fr 1fr 420px;
    padding:35px;
    background-size: cover;
}

.mobileHighRoller{
    display: none;
}

@media only screen and (max-width : 1025px) {
    .homepageComponent{grid-template-columns: 1fr 10px 1fr; height: auto!important;}
}

@media only screen and (max-width : 768px) {
    .homepageComponent{grid-template-columns: 1fr; height: auto !important;}
    
}

@media only screen and (max-width : 460px) {
    .blurryText{
        display: none;
    }

    .homepageComponent{
        padding: 30px 15px;
        background-image: url('../../assets/high-roller-mobile.jpg') !important;
        margin-top:-5px;
    }

    .mobileHighRoller{
        display: block;
        /* color: #FFF;
        font-size: 25px; */
    }
    .hero-text{
      margin-bottom: 16px;
    }
}

.homepageDescription{
  line-height: 29px;
  animation: fadein 3s ease-in forwards;
}

.blurryText{
  background: rgba(51, 51, 51, 0.1);
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  color: var(--color-two);
  padding: 30px;
  border-radius: 10px;
  animation: fadein 1.5s ease-in forwards;
}

@keyframes fadein {
  to {
    transform: scale(.125);
  }
}


.blurryText h3{
    font-size: 36px;
    margin:0px;
    color: var(--color-four);
}
@media only screen and (max-width : 1440px) {
    .blurryText{
        padding: 15px;

    }
}
@media only screen and (max-width : 992px) {
    .blurryText {
        padding-right: 15px;
        margin-bottom: 15px;
    }
    .blurryText h3{font-size: 20px;}
}
.blurryText p{
    font-size: 16px;
line-height: 30px;
}



.PlayNowBtn{
    background: var(--gradient-one);
    border-radius: 10px;
    border:none !important;
    color: #FFF;
    padding: 15px 45px;
    font-size: 17px;
    float: right;
    margin-top: 30px;
    cursor: pointer;
}
















#high-roller-hero-text{
    /* position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    animation: fillcolor 0.5s ease forwards 4s ;
    

    /* font-size: max(2.8rem,1vw,6vh); */
}


 #high-roller-hero-text{
    font-size: max(3.2rem,1vw,6vh);
    padding: 0.15rem;
    fill: #ffae0021;
 }





#high-roller-hero-text path:nth-child(1){
    stroke-dasharray:263.45477294921875;
    stroke-dashoffset: 263.45477294921875;
    animation: text-anim 2s ease forwards;
}
#high-roller-hero-text path:nth-child(2){
  stroke-dasharray:157.60934448242188;
    stroke-dashoffset: 157.60934448242188;
    animation: text-anim 2s ease forwards 0.15s;
}
#high-roller-hero-text path:nth-child(3){
  stroke-dasharray:84.47998046875;
    stroke-dashoffset: 84.47998046875;
    animation: text-anim 2s ease forwards 0.3s;

}
#high-roller-hero-text path:nth-child(4){
  stroke-dasharray:128.14501953125;
    stroke-dashoffset: 128.14501953125;
    animation: text-anim 2s ease forwards 0.45s;

}
#high-roller-hero-text path:nth-child(5){
  stroke-dasharray:133.82955932617188;
    stroke-dashoffset: 133.82955932617188;
    animation: text-anim 2s ease forwards 0.6s;

}
#high-roller-hero-text path:nth-child(6){
  stroke-dasharray:219.68202209472656;
    stroke-dashoffset: 219.68202209472656;
    animation: text-anim 2s ease forwards 0.75s;

}
#high-roller-hero-text path:nth-child(7){
  stroke-dasharray:157.61114501953125;
    stroke-dashoffset: 157.61114501953125;
    animation: text-anim 2s ease forwards 0.9s;

}
#high-roller-hero-text path:nth-child(8){
  stroke-dasharray:116.15998840332031;
    stroke-dashoffset: 116.15998840332031;
    animation: text-anim 2s ease forwards 1s;

}
#high-roller-hero-text path:nth-child(9){
  stroke-dasharray:133.82974243164062;
    stroke-dashoffset: 133.82974243164062;
    animation: text-anim 2s ease forwards 1.15s;

}
#high-roller-hero-text path:nth-child(10){
  stroke-dasharray:178.65594482421875;
    stroke-dashoffset: 178.65594482421875;
    animation: text-anim 2s ease forwards 1.3s;

}
#high-roller-hero-text path:nth-child(11){
  stroke-dasharray:92.1343994140625;
    stroke-dashoffset: 92.1343994140625;
    animation: text-anim 2s ease forwards 1.45s;

}
#high-roller-hero-text path:nth-child(12){
  stroke-dasharray:215.01084899902344;
    stroke-dashoffset: 215.01084899902344;
    animation: text-anim 2s ease forwards 1.6s;

}
#high-roller-hero-text path:nth-child(13){
  stroke-dasharray:159.7749481201172;
    stroke-dashoffset: 159.7749481201172;
    animation: text-anim 2s ease forwards 1.75s;

}
#high-roller-hero-text path:nth-child(14){
  stroke-dasharray:176.55677795410156;
    stroke-dashoffset: 176.55677795410156;
    animation: text-anim 2s ease forwards 1.9s;

}
#high-roller-hero-text path:nth-child(15){
  stroke-dasharray:133.82980346679688;
    stroke-dashoffset: 133.82980346679688;
    animation: text-anim 2s ease forwards 2.05s;

}
#high-roller-hero-text path:nth-child(16){
  stroke-dasharray:84.47991943359375;
    stroke-dashoffset: 84.47991943359375;
    animation: text-anim 2s ease forwards 2.2s;

}
#high-roller-hero-text path:nth-child(17){
  stroke-dasharray:84.47991943359375;
    stroke-dashoffset: 84.47991943359375;
    animation: text-anim 2s ease forwards 2.35s;

}
#high-roller-hero-text path:nth-child(18){
  stroke-dasharray:157.60958862304688;
    stroke-dashoffset: 157.60958862304688;
    animation: text-anim 2s ease forwards 2.5s;

}
#high-roller-hero-text path:nth-child(19){
  stroke-dasharray:86.72232818603516;
    stroke-dashoffset: 86.72232818603516;
    animation: text-anim 2s ease forwards 2.65s;

}
#high-roller-hero-text path:nth-child(20){
  stroke-dasharray:162.5460205078125;
    stroke-dashoffset: 162.5460205078125;
    animation: text-anim 2s ease forwards 2.8s;

}
#high-roller-hero-text path:nth-child(21){
  stroke-dasharray:211.9611358642578;
    stroke-dashoffset: 211.9611358642578;
    animation: text-anim 2s ease forwards 2.95s;

}
#high-roller-hero-text path:nth-child(22){
  stroke-dasharray:157.609619140625;
    stroke-dashoffset: 157.609619140625;
    animation: text-anim 2s ease forwards 3.10s;

} 
#high-roller-hero-text path:nth-child(23){
  stroke-dasharray:157.609619140625;
    stroke-dashoffset: 157.609619140625;
    animation: text-anim 2s ease forwards 3.25s;

}
#high-roller-hero-text path:nth-child(24){
  stroke-dasharray:181.32540893554688;
    stroke-dashoffset: 181.32540893554688;
    animation: text-anim 2s ease forwards 3.40s;

}
#high-roller-hero-text path:nth-child(25){
  stroke-dasharray:138.9844207763672;
    stroke-dashoffset: 138.9844207763672;
    animation: text-anim 2s ease forwards 3.55s;

}

@keyframes text-anim{
    to{
        stroke-dashoffset: 0;
    }
}

@keyframes fillcolor{
    /* from{
        fill: transparent;
    } */
    to{
        fill: #FFAF00;
    }
}



