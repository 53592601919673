.gameBox{background: #e8e8e8; padding: 50px 0px;background-image: url('../../assets/new/bg1.png');
    background-size: contain;}
.gameBox h3{ color: var(--color-one);   font-weight: 300;
    font-size: 22px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;}
.gameDetail{background-color: #e8e8e8;}
    .gameTitle{font-weight: bold; color: var(--color-one); margin-bottom: 30px;}



    .loadMore{
        width: 150px;
        background: var(--color-one);
        color: #FFF;
        border-radius: 5px;
        border:none;
        padding: 10px 0px;
        margin: auto;
    }


    #hide{display: none;}
    #show{display: block;}
