.gamedetailTitle{
    color: var(--color-one);
    font-weight: bold;
}
.gameSubdescription{
    color: var(--color-two);
    font-weight: bold;
}
.gameContent{
    font-size: 17px;
    color: var(--color-one);
}
.gamedetail{
    background-color: #e8e8e8;
    padding: 40px 0px;
    background-image: url('../../assets/new/bg1.png');
background-size: contain;
}