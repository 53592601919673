.bannerSection{
    background-color: #f5f5f5;
    padding: 70px 0px;
}
.bannerSection h2{
    font-size: 70px;
    font-weight: bold;
}
.bannerSection h3{
    font-size: 32px;
}
.bannerImage{
    max-width: 100%;
    display: block;
    margin: auto;
}

@media (max-width: 576px) {
    .bannerSection h2{
        font-size: 32px;
    }
    .bannerSection h3{
        font-size: 16px;
    }
    .bannerImage{
        width: 200px;
        display: block;
        margin:auto;
    }
    .swiper-button-prev{
        left: 34px;
    }
    .swiper-button-next{
        right: 34px;
    }
}
@media (max-width: 768px) {
    .bannerSection h2{
        font-size: 32px;
    }
    .bannerSection h3{
        font-size: 16px;
    }
    .bannerImage{
        width: 200px;
        display: block;
        margin:auto;
    }
}
@media (max-width: 1024px) {
    .bannerSection h2{
        font-size: 38px;
    }
    .bannerSection h3{
        font-size: 20px;
    }
    .bannerImage{
        width: 100%;
        display: block;
        margin:auto;
    }
}

.bannerSection .swiper {    margin: 0px -46px;}
.bannerSection .swiper-slide {padding: 0px 46px;}
.bannerSection h2, .bannerSection h3{
    color: var(--color-one);
    text-align: center;
}


.playBtn{
    background-color: var(--color-two);
    border: none;
    color: #FFF;
    padding: 10px 25px;
    border-radius: 20px;
    display: block;
    margin: auto;
}








.onlinegame{
    padding: 60px 0px;
    background-image: url('../../../assets/new/bg1.png');
    background-size: contain;
}
.onlinegame h2 {
    color: var(--color-one);
    text-align: center;  font-size: 70px;
    font-weight: bold;
    margin-bottom: 40px;
}
@media (max-width: 1024px) {
    .onlinegame h2{
        font-size: 38px;
    }
   
}

@media (max-width: 576px) {
    .onlinegame h2 {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 40px;
    }
}
@media (max-width: 768px) {
    .onlinegame h2 {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 40px;
    }
}

.onlinegame h3{ color:var(--color-one); font-weight: 300; font-size: 22px; text-align: center; margin-top:15px; margin-bottom: 15px;}








.gamefeature{
    padding: 80px 0px;
    color: #FFF;
    text-align: center;
    /* background-color: var(--color-one); */
}

.featureBox{
  padding:30px;
    /* background-image: url('../../../assets/new/featureBox.jpg');
    background-size: cover; */
    height: 424px;
    display: inline-flex !important;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-bottom: 50px;

}
.featureBox0, .featureBox2, .featureBox4{
    background-image: url('../../../assets/new/featureBox2.jpg');
    background-size: cover;
}
.featureBox1, .featureBox3, .featureBox5{
    background-image: url('../../../assets/new/featureBox.jpg');
    background-size: cover;
}
.featureBox h3{
    color: #40651b !important;
}

.featureBox0  h3, .featureBox2  h3, .featureBox4  h3{
color: #FFF !important;
}
.featureBox0  p, .featureBox2  p, .featureBox4  p{
    color: #FFF !important;
}
.gamefeature p{
    margin-top: 15px;
    font-size: 19px;
    line-height: 32px;
    color: #777777;
}



.testimonial{
    padding: 50px 0px;
    background-image: url('../../../assets/new/bg1.png');
    background-size: contain;
}
.testimonialBox{
    font-size: 26px;
    text-align: center;
    color: var(--color-one);        
    padding: 50px 75px;

}
@media (max-width: 1024px) {
    .testimonialBox p{
        font-size: 20px;
    }
   
}
@media (max-width: 576px) {

    .testimonialBox{
        font-size: 16px;
        padding: 25px 43px;
    }
}
.testimonialImage{
    width: 80px;
    height: 80px;
    border-radius: 50px;
    object-fit: cover;
}
.testimonialName{
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    font-size: 20px;
}
.swiper-button-next,  .swiper-button-prev{
    background-color: var(--color-two);
    color: #FFF;
    height: 33px;
    width: 33px;

}
.swiper-button-next:after, .swiper-button-prev:after{
    font-size: 18px;

}