.homepageLatest{
    background: linear-gradient(93.58deg, #3C0104 9.08%, #190102 100%);
    padding: 50px 0px;
}
.homePageLatestTitle{
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 29px;
color: #FFF;
margin-bottom: 50px;
}
.homepageLatestCard{
    background: linear-gradient(156.38deg, #550206 15.22%, #4B0206 84.78%);
   
    box-sizing: border-box;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    margin-bottom: 40px;
    padding: 10px;
}
.homepageLatestCard .title{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    color: #FBF4F4;
    margin-top:15px;
}

.homepageLatestCard .cardDescription{

font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 22px;
color: #FFF;
}
.homepageLatestShowMore{
    width: 220px;
height: 60px;
color: #FFF;
display: block;
margin: auto;
border: 2px solid #FBF4F4;
box-sizing: border-box;
border-radius: 100px;
}
.homepageLatestCard img{
    border-radius: 8px;
    border: 1.5px solid #C99C9C;
}
.homepageLatestShowMore button{
    
background: transparent;
width: 100%;
height: 60px;
color: #FFF;
border: none;
}