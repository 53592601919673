.ListGrid {
  margin-top: 10px;
  margin-bottom: 25px;
  display: grid;
  grid-template-columns: 160px 40px 200px 1fr;
  align-items: center;
  border-radius: 5px;
  color: #fff;
  padding: 15px 34px;
  background: linear-gradient(90deg, #262626 0%, #404040 100%);  
  border: 1px solid #FFF;
  box-sizing: border-box;
  border-radius: 5px;
  transition: ease-in-out .2s;
}

#game_list_card a{
  text-decoration: none;
}
.listImage {
  width: 100%;
  border-radius: 8px;
}
.ListText {
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 0px;
  margin-top:0px;
}
.ListCategory {
  color: var(--color-three);
  margin-bottom: 0px !important;
  margin-top: 7px;
}
.GameDescriptionBox {
  color: #FFF;
  
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    -webkit-box-orient: vertical;

}
@media screen and (min-width: 1100px) {
  .GameSection {
    grid-column: 3/4;
  }
  .GameDescriptionBoxTablet {
    display: none;
  }
}
@media screen and (max-width: 1100px) {
  .GameSection {
    grid-column: 3/5;
  }
  .GameDescriptionBox {
    display: none;
  }
}
@media screen and (max-width: 760px) {
  .ListGrid {
    padding: 10px;
    grid-template-columns: 110px 10px 1fr 0fr;
  }
  .GameDescriptionBox {
    display: none;
  }
  .GameDescriptionBoxTablet {
    display: none;
  }
}
