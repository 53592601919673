.offer{
    background: #e8e8e8;
    color: var(--color-one);
    padding: 50px 0px;
    
background-image: url('../../assets/new/bg1.png');
background-size: contain;
}
.offer a{
    color: var(--color-one) !important;
}
.offer h3{
    font-weight: bold;
}
.offer p{
    font-size: 19px;
}

.rowBorderBottom{
    border-bottom: 1px solid #CCC; 
    padding: 30px;
}



.offerImage{
    max-height: 500px;
    display: block;
    margin: auto;
    max-width: 100%;
}