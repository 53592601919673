.faqs{
    background: #e8e8e8;

}
.faqs{
    color: var(--color-one);
    padding: 50px 0px;
    background-image: url('../../assets/new/bg1.png');
background-size: contain;
}
.faqs h3{
    font-size: 32px;
    font-weight: 600;
}
@media (max-width: 768px) {
    .faqs h3{
        font-size: 23px;
        font-weight: 600;
    }
}
.faqs p{
    font-size: 20px;
}