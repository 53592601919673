
.gameMenuHeading{
    color: #FFF;
    font-weight: 400;
    font-size: 30px;
    margin-top: 0px;
}


.ListCard{
    position: relative;
    width: 100%;
    display: block;
}
.trendinggameBg{
    background: radial-gradient(361.73% 1644.58% at 100% 100%, rgba(20, 20, 20, 0.8) 0%, rgba(26, 26, 26, 0.8) 45.16%, rgba(51, 51, 51, 0.8) 100%), url('../../assets/trendingssbg.png');  
}

