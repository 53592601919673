.footer{background: var(--color-one); color: #FFF; padding: 50px 0px;}
ul{padding: 0px; list-style: none;}
a{color: #FFF; margin-bottom: 10px; display: block;}
a:hover{color: #FFF;}
.footer h3{ font-style: normal; font-weight: 500; font-size: 22px; text-transform: uppercase; color: #FFF; line-height: 22px; margin-bottom: 30px;}
.socialMediaFooter{display: flex; float: left;}
.socialMediaFooter a{margin-right: 15px;}
.copyrightFooter{background: var(--color-two); color: #FFF; padding:15px 0px}
.copyrightFooter p{padding: 0px  !important; margin: 0px  !important;}
.footerLogo{margin-bottom: 15px;}

.mediaLinkss{width: 30px;margin-right: 15px  !important}