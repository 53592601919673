.popularSection{
    background: linear-gradient(93.58deg, #3C0104 9.08%, #190102 100%);
    color: #FFF;
}
.popularTitle{
    color: #FFF;
    line-height: 30px;
}
.popularTitle span{
    color: var(--color-one);
}

