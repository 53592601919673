.recommendedgames{
    background-size: cover !important;
    margin-top: -2px;
}
.sliderbg{
    background:linear-gradient(90deg, #141414 0%, #2B2B2B 8.91%, #2E2E2E 45.83%, #2B2B2B 89.26%, #0D0D0D 100%);

}
@media only screen and (max-width : 768px) { .recommendedgames{display: none;} }

.cardBox{
    /* background: linear-gradient(90deg, #7D4C02 0%, #CE920E 7.58%, #FFAF00 91.98%, #7E4C02 98.66%); */
   
    box-sizing: border-box;
    border-radius: 0px 10px 10px 0px;
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    align-items: center;
   

}
.cardBoxImage, .cardBox{
    border-radius: 10px;
}
.cardBoxImage{
    border: 3px solid var(--color-one);
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.cardBox .cardBoxImage{
    width: 100%;
}
.cardBox .game-name{
    margin: 0px 6px;
    color:#FFF;
   
    height: 100%;
    align-items: center;
    display: grid;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: linear-gradient(90deg, #7D4C02 0%, #EFA910 7.58%, #FFAF00 91.98%, #7E4C02 98.66%);
    font-size: 14px;
    padding: 0px 15px;
    font-weight: bold;
}
.mobileslider-title{font-size: 14px;}
.recommended .mobileslider-title{padding: 7px; }
.recommendedgames .rec-arrow-left{
    right: initial;
    margin-top: 0px;
    z-index: 99;
    margin-left: -7px;

}

.recommendedgames .rec-arrow-right{
    left: initial;
    margin-top: 0px;
    z-index: 99;
    margin-right: -7px;

}

