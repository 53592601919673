.pageBox{
    background: #e8e8e8;
    padding: 50px 0px;
    border-radius: 8px;
    color: #000;
    background-image: url('../../assets/new/bg1.png');
    background-size: contain;
}

.aboutHeading{
    font-size: 34px !important;
    color: var(--color-one);
}
/*.termsBoxTwo{
    background: linear-gradient(90deg, #404040 0%, #595959 100%);
    border-radius: 10px;
    padding: 15px;
}

.termHeading{
    color: #FFF !important;
    text-transform: uppercase;
    line-height: 34px;
}
.marginTop15{
    margin-top: 15px;
}


.aboutbg{
    
        background-image: linear-gradient(93.58deg,rgba(60, 1, 4, 0.3),rgba(25, 1, 2, .9) ), url(../../assets/pagebg.jpeg);
        background-position: center center;
    
}*/