.contactPage{
    background: #e8e8e8;
    padding:50px 0px;
    background-image: url('../../assets/new/bg1.png');
background-size: contain;
}
.contactHeading{
    color: var(--color-one);
    margin-bottom: 20px;
}
.formGroup{
    margin-bottom: 15px;
    margin-left:0px;
}
.contactPage label{
    line-height: 35px;
    margin: 0px;
    padding: 0px;
    font-size: 20px;
    color: var(--color-one);
   
}
.inputText{
    border:1px solid var(--color-one);
    width: 100%;
    font-size: 17px;
    padding: 10px;
}


.qrImage{
    width: 200px;
    display: block;
    margin: auto;
    margin-top: 10px;
}

.SendBtn{
    border:none !important;
    width: 150px;
    background-color: var(--color-one);
    
    color: #FFF;
}


.messageBox{
    background-color: var(--color-one);
    color: #FFF;
    padding: 15px;
    width: 100%;
}
.messageBox h3{font-size: 20px !important;}


.errms{
    margin: 0px;
    padding: 0px;
    font-size: 12px;
    color: red;
}