.header{
    background: #FFF;
    padding: 15px 0px;
}
.topHeader a{margin-bottom: 0px !important;}
.mailphone{
    color: #FFF;
    display: grid;
    grid-template-columns: 1fr 1fr .5fr;
}
.mailphone img{width: 30px; margin-right: 10px;}
.topHeader{
    padding: 7px 0px;
    background-color: var(--color-one);
}
.mobileLogo{
    font-size: 20px;
    margin-left: 25px;
    font-weight: bold;
    top: 3px;
    position: relative;
    text-decoration: none;
}
.mobileLogo:hover{
    color: #FFF;
    text-decoration: none;
}
.menuGrid{
   
    display: grid;
    color: #FFF;
    align-items: center;
    text-align: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr  1fr ;

}
.menuGrid a{
    color: var(--color-one);
    text-decoration: none;
    margin: 0px;
    font-size: 19px;
    padding:7px;
      transition: ease-in-out .2s ;
}
.menuGrid a:hover{
    background-color: var(--color-one);
    color: #FFF;
    border-radius: 4px;
    transition: ease-in-out .2s ;
}
.socialMedia{
    float: right !important;
    display: flex;
}
.socialMedia a{margin-right: 15px;}


@media (max-width: 576px) {
    .mailphone{
       grid-template-columns: 1fr !important; 
       grid-gap: 10px!important;
       margin-bottom: 10px;
    }
    .socialMedia{
        float: left !important;
    }
}
@media (max-width: 768px) {
    .topHeader{display: none;}
    .mailphone{
        grid-template-columns: 1fr 1fr; 
    }
    .socialMedia{
        float: left !important;
    }
}

/* 
.sarchInputWarpper{
    width: 400px;
    background: #312525;
    border-radius: 100px;
    border: none;
    padding: 6px 5px 6px 20px;
    display: grid;
    grid-template-columns: 1fr 38px;
}
.sarchInputWarpper button{
    background: transparent;
    border:none;
    filter: invert();
}
.searchInput{
   background: transparent;
   border:none;
   width: 100%;
   outline: none;
   color: #FFF;
}
.sarchInputWarpper img{
    width: 100%;
    filter: invert();
} */

/* .magnifyGlass{background: transparent; } */
.downloadBtn{background-color: var(--color-one); border-radius: 50px;  border:none; padding: 6px 25px; color: #FFF; transition: ease-in-out .2s;}
.downloadBtn:hover{background-color: var(--color-two); transition: ease-in-out .2s;}

/* 
@media only screen and (max-width :600px) {
    .sarchInputWarpper{width: 100% !important;}
 }
 */

.mobileList{text-align: center; }
.mobileList li a{ color: #FFF;}
#MenuBox{ background: var(--color-one); padding: 15px 15px;}




.mobileMenu{
    background-color: var(--color-one);
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 39px;
    border-radius: 4px;
    float: right;
    cursor: pointer;
}