.fourofour{
    padding: 100px 0px !important ;
    background-color: #e8e8e8;
    background-image: url('../../assets/new/bg1.png');
    background-size: contain;
}


.fourofourHeading{
    font-size: 100px;
    color: var(--color-one);
    text-align: center;
    margin-bottom: 0px;
}
.fourofourtext{
    color: var(--color-one);
    text-align: center;
}

.fourofourLink{
    padding: 10px 15px;
    color: var(--color-one);
    text-align: center;
    border: 2px solid var(--color-one);
    text-align: center;
    display: block;
    width: 100px;
    margin: auto;
    margin-top: 40px;
}
