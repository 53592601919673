.featureSection{ background: linear-gradient(93.58deg, #3C0104 9.08%, #190102 100%); color: #FFF; padding:50px 0px; }
.homepageFeatureCard{ background: linear-gradient(180deg, #310204 1.87%, #0F0001 100%);}
.videoGameTitle{ font-style: normal; font-weight: normal; font-size: 26px; line-height: 42px; color: #FFFFFF;}
.videoGameTitle span{ color: var(--color-one); }
.videowarpper{padding: 0px 80px;}
.video-container { overflow: hidden; position: relative; width:100%;    border-radius: 8px; }
.video-container::after { padding-top: 56.25%; display: block; content: ''; }
.video-container iframe { position: absolute;    top: 0; left: 0; width: 100%; height: 100%;}
.VideoGameShowMore{ margin:0px !important}
@media only screen and (max-width :984px) {
    .videowarpper{padding: 0px !important; margin-bottom: 25px !important;}
    .videoGameTitle, .VideoGameShowMore{ text-align: center; display: block; margin: auto !important; margin-bottom: 15px !important; } 
}
@media only screen and (max-width :600px) {
    .videowarpper{padding: 0px !important;}
    .videoGameTitle{margin-top: 25px;}

    .videoGameTitle, .VideoGameShowMore{text-align: center; display: block; margin: auto ;}
}