.brandGame{
    background: #e8e8e8;
    padding: 50px 0px;
}
.brandTitle{
    font-weight: bold;
    color: var(--color-one);
    margin-bottom: 30px;
}
.storeContent{
    font-size: 19px;
    color: var(--color-one);
}
.brandGame h3 {
 
    color: var(--color-one);
    font-weight: 300;
    font-size: 22px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
}