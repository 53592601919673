.branddetail{
    background: #e8e8e8;
    padding:50px;
    background-image: url('../../assets/new/bg1.png');
background-size: contain;
}
.branddetailImage{
    position: sticky;
    top: 20px;
}
.branddetail h2{font-weight: 600; color: var(--color-one);}
.brandDetailDescription{font-size: 19px; color: var(--color-one);}
.branddetailCard h3 {
    color: var(--color-one);
    font-weight: 300;
    font-size: 22px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.milkywaygameTitle{
    color: var(--color-one);
}